<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">项目名称：</span><input type="text" v-model="form.itemName"/></li>
            <li class="up"><span>属于大类：</span>
              <select v-model="form.itemDaleiId" @change="setDaleiName">
                <option :value="0">无分类</option>
                <option :value="dalei.id" v-for="dalei in itemDaleiList" :key="dalei.id">{{dalei.name}}</option>
              </select>
            </li>
            <li class="up"><span class="import">分配门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName" :disabled="this.$store.state.User.jibieId!=0">
                <option :value="0">全部门店</option>
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <li class="up"><span class="import">默认价格：</span><input type="number" v-model="form.dfprice" > 元</li>
            <li class="up">
              <span class="import">扣除成本：</span><input type="number" v-model="form.costBFB" :disabled="this.$store.state.User.daoqishijian<0"> %&nbsp;&nbsp;<input type="number" v-model="form.costGD" :disabled="this.$store.state.User.daoqishijian<0"> 元
              <img src="@/assets/images/vip.png">
              <i class="icon-alert-circle" title="如果同时设置百分比与固定,则先扣百分比,后扣固定。"></i>
            </li>
            <li class="up"><span class="import">会员优惠：</span>
              <select v-model="form.isDis"> 
                <option :value="0">不允许</option>
                <option :value="1">允许</option>
              </select>
            </li>
            <li class="up" v-if="form.isDis==1"><span class="import">会员优惠方式：</span>
              <select v-model="form.metDis"> 
                <option :value="0">会员优惠</option>
                <option :value="1">固定价格</option>
              </select>
              <template v-if="form.metDis==1"><input type="number" v-model="form.VIPlvPrice"/> 元</template>
            </li>
          </ul>
          <ul class="qiehuan" v-if="form.isDis==1&&form.metDis==1">
            <div class="qiehuan_title">会员等级价格<i class="icon-alert-circle" title="会员等级价格是指此项目固定会员价，不再享会员优惠。"></i></div>
            <div class="qiehuan_xunhuan" v-for="(item , i) in VIPlvPriceList" :key="i">
              <div class="qiehuan_del" @click="delXunhuan(i,'VIPlvPriceList')"><i class="icon-x"></i></div>
              <li class="up"><span>会员等级-价格：</span>
                <select v-model="item.VIPlvId">
                  <option :value="viplv.id" v-for="viplv in VIPlvList" :key="viplv.id">{{viplv.VIPLVName}}</option>
                </select><input type="number" v-model="item.VIPlvPrice"/> 元
              </li>
            </div>
            <div class="qiehuan_button" @click="addXiangMu('VIPlvPriceList')">添加</div>
          </ul>
          <ul>
            <li class="up"><span class="import">提成方案：</span>
              <select v-model="form.percentagePlan" :disabled="$store.state.User.daoqishijian<0"> 
                <option :value="1">自由分配</option>
                <option :value="2">限定总额</option>
              </select>
              <img src="@/assets/images/vip.png">
            </li>
            <li class="up" v-if="form.percentagePlan==2">
              <span class="import">总计提成：</span><input type="number" v-model="form.allBFB" :disabled="$store.state.User.daoqishijian<0"> %&nbsp;&nbsp;<input type="number" v-model="form.allGD" :disabled="$store.state.User.daoqishijian<0"> 元
              <img src="@/assets/images/vip.png">
              <i class="icon-alert-circle" title="如提成方案为限定总额,则提成总计不能超过总计提成。&#10;提成会以次->从->副->主的顺序发放，直至达到总计提成。"></i>
            </li>
            <li class="up">
              <span class="import">主服默认提成：</span><input type="number" v-model="form.onebaifenbi" :disabled="$store.state.User.daoqishijian<0"> %&nbsp;&nbsp;<input type="number" v-model="form.oneguding" :disabled="$store.state.User.daoqishijian<0"> 元
              <img src="@/assets/images/vip.png">
              <i class="icon-alert-circle" title="主服默认提成是主服务人员的默认提成，项目价格按照主服务人员级别计算！&#10;例:项目默认100元，某等级200元，主服级别为这个等级，项目价格为200元。&#10;提成计算方式为 实际支付价格*百分比+固定提成"></i>
            </li>
            <li class="up">
              <span >副服默认提成：</span><input type="number" v-model="form.twobaifenbi" :disabled="$store.state.User.daoqishijian<0"> %&nbsp;&nbsp;<input type="number" v-model="form.twoguding" :disabled="$store.state.User.daoqishijian<0"> 元
              <img src="@/assets/images/vip.png"></li>
            <li class="up">
              <span >从服默认提成：</span><input type="number" v-model="form.threebaifenbi" :disabled="$store.state.User.daoqishijian<0"> %&nbsp;&nbsp;<input type="number" v-model="form.threeguding" :disabled="$store.state.User.daoqishijian<0"> 元
              <img src="@/assets/images/vip.png"></li>
            <li class="up">
              <span class="import">次卡消费：</span>
              <label><input type="radio" value="1" v-model="form.oncika" @change="addcikaticheng"/>允许</label><label><input type="radio" value="2" v-model="form.oncika"/>不允许</label>
            </li>
            <li class="up" v-if="form.oncika==1">
              <span class="import">次卡提成标准价格：</span><input type="number" v-model="form.cikaticheng" :disabled="$store.state.User.daoqishijian<0"> 元
              <img src="@/assets/images/vip.png">
              <i class="icon-alert-circle" title="次卡提成标准价格是指消费次卡、提成又是百分比的时候的原始价格。&#10;例:主服提成30%，项目价格为100，次卡提成标准价格为50。&#10;则次卡消费提成为：主服提成=50*30%=15元"></i>
            </li>
          </ul>
          <ul class="qiehuan">
            <div class="qiehuan_title">级别价格 <img src="@/assets/images/vip.png"><i class="icon-alert-circle" title="级别价格是指不同员工对同种消费项目价格的差异。&#10;如不设置，所有员工对此消费项目的价格都为默认价格。"></i></div>
            <div class="qiehuan_xunhuan" v-for="(item , i) in gangweiList" :key="i">
              <div class="qiehuan_del" @click="delXunhuan(i,'gangweiList')"><i class="icon-x"></i></div>
              <li class="up"><span>岗位级别：</span>
                <select v-model="item.ganglvId" @change="setShareName(item)" :disabled="$store.state.User.daoqishijian<0">
                  <option :value="share.id" v-for="share in shareList" :key="share.id">{{share.shareName}}</option>
                </select>
              </li>
              <li class="up"><span>级别价格：</span><input type="number" v-model="item.lvdfprice" :disabled="$store.state.User.daoqishijian<0"/> 元</li>
              <template v-if="form.isDis==1&&form.metDis==1">
                <li class="up"><span>会员价格：</span><input type="number" v-model="item.VIPlvdfprice" :disabled="$store.state.User.daoqishijian<0"/> 元</li>
                <ul class="qiehuan">
                  <div class="qiehuan_title">会员等级价格<i class="icon-alert-circle" title="会员等级价格是指此项目此级别固定会员价，不再享会员优惠。"></i></div>
                  <li class="up" v-for="(viplv,i) in item.VIPlvPriceList" :key="i">
                    <span>会员等级-价格：</span>
                    <select v-model="viplv.VIPlvId" :disabled="$store.state.User.daoqishijian<0">
                      <option :value="vip.id" v-for="vip in VIPlvList" :key="vip.id">{{vip.VIPLVName}}</option>
                    </select><input type="number" v-model="viplv.VIPlvPrice" :disabled="$store.state.User.daoqishijian<0"/> 元
                  </li>
                  <div class="qiehuan_button" @click="addVIPlvPrice(i)">添加</div>
                </ul>
              </template>
              <li class="up"><span>次卡消费：</span><label><input type="radio" value="1" v-model="item.lvoncika" @change="addlvcikaticheng(item)" :disabled="$store.state.User.daoqishijian<0"/>允许</label><label><input type="radio" value="2" v-model="item.lvoncika" :disabled="$store.state.User.daoqishijian<0"/>不允许</label></li>
              <li class="up" v-if="item.lvoncika==1">
                <span class="import">次卡提成标准价格：</span><input type="number" v-model="item.lvcikaticheng" > 元
              </li>
            </div>
            <div class="qiehuan_button" @click="addXiangMu('gangweiList')">添加</div>
          </ul>
          <ul>
            <li class="up"><span>备注信息：</span><textarea v-model="form.info"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {deepCopy,testStr} from '@/utils/util'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
          this.gangweiList=deepCopy([this.gangweiData])
          this.VIPlvPriceList=deepCopy([this.VIPlvPriceData])
        }else{
          this.form=deepCopy(newD.data)
          this.gangweiList=this.form.gangweijiage
          this.VIPlvPriceList=this.form.VIPlvPriceList
          this.delgangweiList=this.gangweiList.map(e=>e.id)
        }
      },
      deep:true
    }
  },
  data() {
    return {
      storeList:[],
      shareList:[],
      delgangweiList:[],
      itemDaleiList:[],
      VIPlvList:[],
      VIPlvPriceList:[
        {
          VIPlvId:null,
          VIPlvPrice:null,
        }
      ],
      gangweiData:{
        ganglvId:null,
        ganglvName:'',
        lvdfprice:null,
        VIPlvdfprice:null,
        lvoncika:2,
        lvcikaticheng:0,
        VIPlvPriceList:[
          {
            VIPlvId:null,
            VIPlvPrice:null,
          }
        ],
      },
      VIPlvPriceData:{
        VIPlvId:null,
        VIPlvPrice:null,
      },
      gangweiList:[
        {
          ganglvId:null,
          ganglvName:'',
          lvdfprice:null,
          VIPlvdfprice:null,
          lvoncika:2,
          lvcikaticheng:0,
          VIPlvPriceList:[
            {
              VIPlvId:null,
              VIPlvPrice:null,
            }
          ],
        }
      ],
      form:{
        itemName:'',
        itemDaleiId:0,
        itemDaleiName:'无分类',
        fenpeimendianId:0,
        fenpeimendianName:"全部门店",
        costBFB:0,
        costGD:0,
        dfprice:null,
        isDis:1,
        metDis:0,
        VIPlvPrice:null,
        percentagePlan:1,
        allBFB:0,
        allGD:0,
        onebaifenbi:0,
        oneguding:0,
        twobaifenbi:0,
        twoguding:0,
        threebaifenbi:0,
        threeguding:0,
        oncika:2,
        info:'',
        cikaticheng:0,
      },
      defform:{
        itemName:'',
        itemDaleiId:0,
        itemDaleiName:'无分类',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.jibieId==0?"全部门店":this.$store.state.User.fenpeimendianName,
        costBFB:0,
        costGD:0,
        dfprice:null,
        isDis:1,
        metDis:0,
        VIPlvPrice:null,
        percentagePlan:1,
        allBFB:0,
        allGD:0,
        onebaifenbi:0,
        oneguding:0,
        twobaifenbi:0,
        twoguding:0,
        threebaifenbi:0,
        threeguding:0,
        oncika:2,
        info:'',
        cikaticheng:0,
      }
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    addcikaticheng(){
      this.form.cikaticheng=this.form.dfprice
    },
    addlvcikaticheng(item){
      item.lvcikaticheng=item.lvdfprice
    },
    init(){
      if(this.MmsData.new){
        this.form=deepCopy(this.defform)
        this.gangweiList=deepCopy([this.gangweiData])
        this.VIPlvPriceList=deepCopy([this.VIPlvPriceData])
      }else{
        this.form=deepCopy(this.MmsData.data)
        this.gangweiList=this.form.gangweijiage
        this.VIPlvPriceList=this.form.VIPlvPriceList
        this.delgangweiList=this.gangweiList.map(e=>e.id)
      }
      this.getStore()
      this.getShare()
      this.getDalei()
      this.getVIPlv()
    },
    getDalei(){
      axios.post('/item/listDalei').then((res) => {
        if(res.code===5000){
          this.itemDaleiList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    delXunhuan(index,name){
      this[name].splice(index,1)
    },
    addXiangMu(name){
      if(name=='gangweiList'){
        this[name].push(deepCopy(this.gangweiData))
      }else{
        this[name].push(deepCopy(this.VIPlvPriceData))
      }
    },
    addVIPlvPrice(i){
      this.gangweiList[i].VIPlvPriceList.push(deepCopy(this.VIPlvPriceData))
    },
    getShare(){
      axios.post('/share/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.shareList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getVIPlv(){
      axios.post('/viplv/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.VIPlvList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    setDaleiName(){
      let dalei=this.itemDaleiList.find(e=>e.id==this.form.itemDaleiId)
      if(dalei) this.form.itemDaleiName=dalei.name
      else this.form.itemDaleiName="无"
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
      else this.form.fenpeimendianName="全部门店"
      this.getShare()
    },
    setShareName(item){
      let share=this.shareList.find(e=>e.id==item.ganglvId)
      if(share) item.ganglvName=share.shareName
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.itemName)){
        this.$Mesg({
          content: '消费项目名称不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.dfprice)){
        this.$Mesg({
          content: '默认价格不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      let gangweijiage=this.gangweiList.filter(e=>{
        if(this.form.isDis==1&&this.form.metDis==1&&e.ganglvId&&!testStr(e.lvdfprice)&&e.ganglvName){
          e.VIPlvPriceList = e.VIPlvPriceList.filter(a=>(a.VIPlvId&&!testStr(a.VIPlvPrice)))
        }else e.VIPlvPriceList=[]
        return (e.ganglvId&&!testStr(e.lvdfprice)&&e.ganglvName)
      })
      if(this.form.isDis==1&&this.form.metDis==1){
        this.VIPlvPriceList = this.VIPlvPriceList.filter(a=>(a.VIPlvId&&!testStr(a.VIPlvPrice)))
      }else this.VIPlvPriceList=[]
      axios.post(this.MmsData.new?'/item/add':'/item/edit',{...this.form,...{gangweijiage:gangweijiage,VIPlvPriceList:this.VIPlvPriceList}}).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: this.MmsData.new?'新增消费项目成功！':'修改消费项目成功！'})
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
