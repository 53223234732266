<template>
<div class="meg_all">
    <div class="meg">
    <h1>消费项目分类管理</h1>
    <div class="meg_g">
        <div class="meg_g_1">
          <span style="color:#F1C40F">消费项目分类只为快速找到项目。</span>
          <span style="color:#F1C40F">   如需修改，请删除重建！</span>
        </div>
        <div class="meg_g_3">
          <div class="list_d">
            <table>
              <thead>
                <tr>
                  <td style="text-align:center">大类编号</td>
                  <td style="text-align:center">类名</td>
                  <td>操作</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemDaleiList" :key="item.id">
                  <td style="text-align:center">{{item.id}}</td>
                  <td style="text-align:center">{{item.name}}</td>
                  <td>
                    <div class="buttonGroup">
                      <button type="button" class="err" @click="delOneData(item.id,item.name)" v-pow:2403>删除</button>
                    </div>
                  </td>
                </tr>
                <tr v-pow:2401>
                  <td style="text-align:right">大类名称：</td>
                  <td style="text-align:left"><input class="dongduInput" v-model="form.name" type="text"  @keypress.enter="addOneData"></td>
                  <td>
                    <div class="buttonGroup">
                      <button type="button" class="suc" @click="addOneData">增加</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
    <div class="meg_c"><button type="button" class="off" @click="closdMeg()">关闭</button></div>
    <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
    </div>
</div>
</template>

<script>
import axios from '@/http/axios'
import {testStr} from '@/utils/util'
export default {
  name: 'home',
  props: {},
  data () {
    return {
      form:{
        name:''
      },
      itemDaleiList:[],
    }
  },
  created () {
    this.init()
  },
  mounted () { },
  components: {},
  methods: {
    addOneData(){
      if(testStr(this.form.name)){
        this.$Mesg({
          content: '大类名称不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      axios.post('/item/addDalei',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: '新增大类成功！'})
          this.getData()
          this.form.name=''
        }else{
          this.$Mesg({content: res.msg,errOrSuc:'err'})
        }
      })
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/item/delDalei',{id}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData()
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    getData(){
      axios.post('/item/listDalei').then((res) => {
        if(res.code===5000){
          this.itemDaleiList=res.data
          this.$emit('resetDalei',res.data)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    init(){
      this.getData()
    },
    closdMeg () {
      this.$emit('closdMeg')
    },
  }
}
</script>
