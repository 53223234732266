<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>项目分类：</p>
          <select v-model="form.itemDaleiId" @change="getData(1)">
            <option value="0">全部</option>
            <option :value="dalei.id" v-for="dalei in itemDaleiList" :key="dalei.id">{{dalei.name}}</option>
          </select>
        </li>
        <li>
          <p>项目名称：</p>
          <input type="text" v-model="form.itemName" @keypress.enter="getData(1)">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <ul>
        <li v-for="item in dataList" :key="item.id">
            <h3>{{item.itemName}}</h3>
            <p>分配门店：{{item.fenpeimendianName}}</p>
            <p>默认价格：{{getDFJiage(item)}}</p>
            <p>所属大类：{{item.itemDaleiName?item.itemDaleiName:'无'}}</p>
            <p class="about"><span>[ {{item.chuangjianrenName}} ]</span><span>{{item.chuangjianshijian.substr(0,10)}}</span></p>
            <p class="going" v-if="countPow(item.fenpeimendianId)" @click="setInfoData(item)" v-pow:2402>消费项目详情&gt;</p>
            <div class="del" v-if="countPow(item.fenpeimendianId)" @click="delOneData(item.id,item.itemName)" v-pow:2403><i class="icon-x"></i></div>
        </li>
      </ul>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:2401>新增消费项目</button>
        <button type="button" class="ora" @click="openDafenlei" v-pow:9999>消费项目分类管理</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
    <item-dalei-window-vue v-if="dafenleiWindowShow" @closdMeg="dafenleiWindowShow=false" @resetDalei="addMegDaleiList"></item-dalei-window-vue>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
import {sortFn} from '@/utils/util'
import itemDaleiWindowVue from './itemDaleiWindow.vue'

export default {
  name: 'packageSettings',
  data() {
    return {
      infoShow: false,
      title:'',
      itemDaleiList:[],
      dafenleiWindowShow:false,
      dataList:[],
      storeList:[],
      form:{
        itemName:'',
        itemDaleiId:0,
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
    if(this.$store.state.User.jibieId==0) this.getStore()
    this.getData()
    this.getDalei()
  },
  components: {PSinfo,itemDaleiWindowVue},
  mounted() {},
  computed:{
    countPow(){
      return (fenpeimendianId)=>{
        if(this.$store.state.User.jibieId==0) return true
        if(this.$store.state.User.fenpeimendianId==fenpeimendianId) return true
        else return false
      }
    }
  },
  methods: {
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    openDafenlei(){
      this.dafenleiWindowShow=true
    },
    addMegDaleiList(e){
      this.itemDaleiList=e
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/item/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    getDFJiage(item){
      let dfArr=[]
      dfArr.push(item.dfprice)
      if(item.gangweijiage.length){
        item.gangweijiage.forEach(e=>{
          dfArr.push(e.lvdfprice)
        })
      }
      let Arr=sortFn(dfArr)
      return Arr.join('元/')+'元'
    },
    getProductNum(item){
      let dfArr=[]
      if(item.productList.length){
        item.productList.forEach(e=>{
          dfArr.push(e.productName+':'+e.num)
        })
      }
      if(dfArr.length){
        return dfArr.join('，')
      }else return '无'
      
    },
    
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/item/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          let {data,gangweijiage,VIPlvPriceList,total}=res
          gangweijiage.forEach(e=>{
            e.VIPlvPriceList=VIPlvPriceList.filter(a=>a.ganglvId==e.ganglvId&&a.itemId==e.itemId)
          })
          data.forEach(e=>{
            e.VIPlvPriceList=VIPlvPriceList.filter(a=>a.ganglvId==0&&e.id==a.itemId)
            e.gangweijiage=gangweijiage.filter(a=>a.itemId==e.id)
          })
          this.dataList=data
          this.pageData.total=total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getDalei(){
      axios.post('/item/listDalei').then((res) => {
        if(res.code===5000){
          this.itemDaleiList=res.data
          
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
    newProductAdd(){
      this.title="新增消费项目"
      this.MmsData.new=true
      this.infoShow=true
    },
    setInfoData(item){
      this.title=item.itemName
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    }
  },
}
</script>
